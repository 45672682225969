<template>
    <b-card class="event-infos-card h-100">
    <template #header>
      <h4 class="mb-0">{{$t('Chat')}}</h4>
      <div>
      <!-- <feather-icon class="mr-1 vue-draggable-handle" size="1x" icon="MoveIcon" v-b-tooltip.hover title="Move Card" />
      <feather-icon
          @click="minMaxSize"
          size="1x"
          :icon="isOriginalSize == true ? 'PlusIcon' : 'MinusIcon'"
          v-b-tooltip.hover
          :title="isOriginalSize == true ? 'Maximise' : 'Minimise'"
      /> -->
      </div>
    </template>        <!-- Main Area-->
        <section class="chat-app-window">
            <!-- User Chat Area -->

            <vue-perfect-scrollbar
                ref="refChatLogPS"
                id="refChatLogPS"
                :settings="perfectScrollbarSettings"
                class="user-chats scroll-area"
                @ps-scroll-y="scrollHandle"
                >
                <div class="d-flex justify-content-center mb-1" v-if="isLoading">
                  <b-spinner label="Loading..." />
                </div>
                <chat-log
                    ref="test"
                    v-if="items.length"
                    :chat-data="items"
                    :booking-id="bookingId"
                />
            </vue-perfect-scrollbar>
            <!-- Message Input -->
            <span class="badge badge-pill badge-primary chat-unread" v-if="alertUnread">
              {{$t('New messages available')}}</span>
            <b-form
                class="chat-app-form"
                @submit.prevent="sendMessage"
                >
                <b-input-group class="input-group-merge form-send-message mr-1">
                    <b-form-input
                    v-model="chatMessage"
                    :placeholder="$t('Enter your message')"
                    :disabled="chatTextBox"
                    id="message-input"
                    />
                </b-input-group>
                <b-button
                    variant="success"
                    type="submit"
                    :disabled="!chatMessage"
                >
                  {{$t('Send')}}
                </b-button>
            </b-form>

        </section>
    </b-card>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,BCard,VBTooltip,BAlert,BSpinner
} from 'bootstrap-vue'
import ChatLog from './ChatLog.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  components: {
        FeatherIcon,
        // BSV
        BAvatar,
        BDropdown,
        BDropdownItem,
        BForm,
        BInputGroup,
        BFormInput,
        BButton,
        BCard,
        BAlert,
        VBTooltip,
        BSpinner,

        // 3rd Party
        VuePerfectScrollbar,
        ChatLog,
        // ChatActiveChatContentDetailsSidedbar,
        // ChatLeftSidebar

    },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    bookingId: {
      type: String,
      default: 0,
    },
  },
    data() {
        return {
            perfectScrollbarSettings : {maxScrollbarLength: 150},
            chatMessage:"",
            shallShowActiveChatContactSidebar:false,
            items:[],
            isOriginalSize:true,
            page:1,
            scrollMessage:false,
            alertUnread:false,
            isLoading:false,
            chatTextBox:false,
        }
    },
    mounted(){
        this.getChat();
        let interval = setInterval(() => {
          this.getChat();
        }, 5000);
        this.$store.commit("CHAT_INTERVAL", interval);
    },
    methods:{
        scrollHandle(evt) {
          //get old 20 records
          if(evt.target.scrollTop  == 0) {
            this.getOldChat();
          }
          // new message available
          if(evt.target.scrollTop < 1047){
            this.scrollMessage = true
          }else{
            this.scrollMessage = false
            this.alertUnread = false
          }
        },
        getChat(){
          let last_time_stamp = this.items[this.items.length - 1]
          this.loaded = false
          this.$http.get(`/chats/${this.bookingId}`,{ params: { last_time_stamp: last_time_stamp?last_time_stamp.created_at:"" } })
          .then(response => {
            if(response.data.length){
              let chatData = response.data.reverse();
              let latest_time_stamp = chatData[chatData.length - 1]
              if((latest_time_stamp != last_time_stamp) && this.scrollMessage){
                this.alertUnread = true
              }
               chatData.forEach(element => {
                this.items.push(element)
              });
              setTimeout(() => {
                this.scrollUp();
              }, 100)
            }
          })
          .catch((error) => {
              console.log(error);
          })
        },
        getOldChat(){
          this.isLoading = true
          this.page++
          this.loaded = false
          this.$http.get(`/chats/old/${this.bookingId}`,{ params: { page:this.page } })
          .then(response => {
            if(response.data.data.length){
              let chatData = response.data.data.reverse();
                this.items.unshift(...chatData)
            }
             this.isLoading = false
            setTimeout(() => {
              this.scrollUp();
            }, 100)
          })
          .catch((error) => {
              console.log(error);
          })
        },

        sendMessage(){
          this.chatTextBox = true
          let message = this.chatMessage
          let url = `/chats`
          this.$http.post(url, { message: message, booking_id: this.bookingId})
            .then(response => {
              this.getChat();
              this.chatMessage = ""
              this.chatTextBox = false
               setTimeout(() => {
                  this.scrollUp();
                }, 100)
            })
            .catch((error) => {
              this.chatTextBox = false
              console.log(error)
            })
        },
        minMaxSize(){
          this.$emit('min-max-file-upload',{index:1,max:this.isOriginalSize})
          this.isOriginalSize = !this.isOriginalSize;
        },

        scrollUp(){
          var l = document.getElementsByClassName("chat").length;
          let lastMessageHeight = document.getElementsByClassName("chat")[l-1].clientHeight;
          const element = document.getElementById('refChatLogPS');
          element.scrollTop = element.scrollHeight +  lastMessageHeight;
          this.alertUnread = false
          document.getElementById("message-input").focus();
        }
    }

}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.card .event-infos-card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.scroll-area {
  position: relative;
  margin: auto;
  height: 419px !important;
}

.chat-app-window {
  // User chats
  position: relative;
  .user-chats {
    background-image: url($chat-bg-light);
   }
}

.chat-app-window .chats .chat-body .chat-content {
    background-image: linear-gradient(80deg, #7367f0, #9e95f5);
}
.chat-unread{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 70px;
}
</style>
