<template>
    <div class="chats">
    <div
      v-for="(msgGrp, index) in chatData"
      :id="index"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.sender_id !== userData.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.avatar_url"
        />
      </div>
      <div class="chat-body">
        <div
          class="chat-content"
        >
          <p class="user-name-chat" v-if="msgGrp.sender_id !== userData.id">{{msgGrp.user_name }}</p>
         <div class="d-flex">
          <p class="">{{msgGrp.message }}</p>
          <p class="message-time">{{ getHumanDate(msgGrp.created_at) }}</p>
         </div>
        </div>
      </div>
    </div>

  </div>
  
</template>
<script>
import { BAvatar } from 'bootstrap-vue'
import moment from 'moment'

export default {
    components: {
    BAvatar,
  },
   props: {
    chatData: {
      type: Array,
      required: true,
    },
    bookingId: {
      type: String,
      default: 0,
    },
   
  },
  directives:{
  },
   data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      busy:false,
      count:0,
      page: 1,
    };
  },
  mounted() {
    const element = document.getElementById('refChatLogPS');
    element.scrollTop = element.scrollHeight;
  },
  methods:{     
    getHumanDate(value){
     
      return moment(new Date(value)).format('hh:mm A'); 
    } 
  },
    
}
</script>
<style>
.user-name-chat{
    font-size: 10px;
    color: #7a6ef1;
    font-weight: 900;
}
.message-time{
    font-size: 10px;
    justify-content: right;
    margin: 5px 0px 0px 10px !important;
}
.chat-avatar .b-avatar{
    background-color: white;
    color: #7c71f1;
}
</style>