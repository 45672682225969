<template>
  <div>
    <b-card title="Employees Management List">
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="8" xl="8">
          <vue-autosuggest :suggestions="filteredOptions" :input-props="inputProps" @selected="onSelected" :limit="10"
            @input="onInputChange">
            <template slot-scope="{suggestion}">
              <div class="d-flex align-items-center">
                <b-avatar :src="suggestion.item.avatar" />
                <div class="detail ml-50">
                  <b-card-text class="mb-0">
                    {{ suggestion.item.name }}
                  </b-card-text>
                </div>
              </div>
            </template>
          </vue-autosuggest>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4" xl="4">
          <b-button v-b-tooltip.hover title="Add Employee to Event Room" variant="success"
            class="d-flex justify-content-end" @click="addToBookingUser" :disabled="!userData.is_admin">
            {{ $t('Add Employee to Event Room') }}
          </b-button>
        </b-col>
      </b-row>
      <div class="mt-2">
        <!-- <b-overlay :show="!loaded" rounded="sm"> -->
        <b-row>
          <b-table ref="refaccountCampaignListTable" class="position-relative" responsive primary-key="id"
            :items="items" :per-page="perPage" :current-page="currentPage" :fields="tableColumns" :sort-by.sync="sortBy"
            :filter="filter" @filtered="onFiltered" show-empty :empty-text="$t('No matching records found')">
            <template #cell(name)="data">
              <b-avatar class="mr-1" :src="data.item.avatar"/>
              {{ data.item.name }}
            </template>
            <template #cell(pivot.role)="data">
              <b-form-checkbox v-model="data.item.pivot.role" inline class="custom-control-success mr-0 mt-50"
                value="admin" unchecked-value="" @change="updateUsersRole($event,data.item.id)" />


            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-button v-b-tooltip.hover title="Delete Notification" variant="gradient-danger" icon="EyeIcon" size="sm"
                class="mr-2" @click="deleteUserFromBooking(data.item.id)" :disabled="!userData.is_admin">
                {{ $t('Delete') }}
              </b-button>


            </template>
          </b-table>
        </b-row>
        <!-- </b-overlay> -->
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BOverlay,
  BRow,
  BTable,
  BImg,
  BCardText,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  props: {
    bookingId: {
      type: String,
      default: "",
    },
    employees:{
      type: Array,
      default: () => [],
    }

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardGroup,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRating,
    BTable,
    BCardBody,
    BCardFooter,
    BRow,
    BCarousel,
    BCarouselSlide,
    BCol,
    BOverlay,
    vSelect,
    BImg,
    VueAutosuggest,
    BCardText,
    BFormCheckbox,
    VBTooltip,
  },
  data() {
    return {
      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Type 'e'",
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      limit: 10,
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableColumns: [
        {
          key: 'name',
          sortable: true,
          label: this.$i18n.t('Name'),
        },
        {
          key: 'email',
          sortable: true,
          label: this.$i18n.t('Email'),
        },
        {
          key: 'pivot.role',
          sortable: true,
          label: this.$i18n.t('Role:Admin'),
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions')
        },
      ],
      items: [],
      is_admin:false
    }
  },
  created(){
    this.$http.get('/client/users')
      .then(res => {
        this.datasuggest = res
      })
  },
  mounted() {
    this.getData()
  },
  computed: {
  },
  methods: {
    getData(){
      this.$http.get(`/client/booking-users?booking_id=${this.bookingId}`)
        .then(res => {
          this.items = res.data.users
           })
    },
    deleteUserFromBooking(value) {
      this.$http.delete(`/client/booking-users`, { params: { booking_id: this.bookingId, booking_user_id: value } })
        .then(res => {
          this.$bvToast.toast(this.$i18n.t('Item deleted'), {
            title: this.$i18n.t('Success'),
            variant: 'success',
            toaster: 'b-toaster-bottom-right'
          })
          this.getData();
        })
    },

    onSelected(option) {
      this.selected = option.item
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filtered = this.datasuggest.data.items.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filtered,
      }]
    },
    updateUsersRole(event,value){
      const result = this.items.filter(item => {
          if(item.pivot.role == 'admin'){
            return item;
          }
      });
      if (result.length >= 1){
        this.$http.post(`/client/booking-users`,
          {
            booking_id: this.bookingId,
            booking_user_id: value,
            role: event
          })
          .then(res => {
            this.$bvToast.toast(this.$i18n.t('Item saved'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right'
            })
            this.getData();
          })
      }else{
        this.$bvToast.toast(this.$i18n.t('Before you can remove the admin, please select an another user as admin'), {
          title: this.$i18n.t('Error'),
          variant: 'danger',
          toaster: 'b-toaster-bottom-right'
        })
      }

    },

    addToBookingUser(){
      this.selected.booking_id = this.bookingId

      this.helperAddItem(`/client/user-booking`,this.selected)
      this.getData();
    },
  }

}
</script>
<style lang="scss">
ul {
  list-style: none;
}
.autosuggest__results-container .autosuggest__results ul li {
  list-style: none;
}

[dir] .autosuggest__results-container .autosuggest__results {
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08) !important;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08) !important;
}
.autosuggest__results-container .autosuggest__results {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
}
.autosuggest__results-container {
  position: relative;
  z-index: 999;
}
[dir] .autosuggest__results-container .autosuggest__results ul li {
  padding: 0.75rem 1rem;
}
[dir=ltr] .autosuggest__results-container .autosuggest__results ul {
  padding-left: 0;
}
[dir] .autosuggest__results-container .autosuggest__results ul {
  margin-bottom: 0;
}
</style>
