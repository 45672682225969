<template>
  <div v-if="currentItem">
    <b-row>
      <b-col cols="12">
      <EventHeader v-if="currentItem" :event="currentItem"></EventHeader>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="order-1">
        <event-infos v-if="currentItem" :event="currentItem"></event-infos>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="order-1">
        <chat v-if="currentItem" :booking-id="currentItem.id"></chat>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="order-1">
        <file-uploader v-if="currentItem" :event="currentItem" @file-display="viewFileLightBox"></file-uploader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <video-call v-if="currentItem" :event="currentItem" :event-id="currentItem.event.id" :booking-id="booking_id">
        </video-call>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <EmployeesManagementList
            v-if="currentItem && currentItem.event.payment_type == 1 && currentItem.event.max_members > 1"
            :booking-id="currentItem.id"
            :employees="currentItem.users">
        </EmployeesManagementList>
      </b-col>
    </b-row>

    <light-box :media="media" v-if="showLightBox" @onClosed="closeLightBox"></light-box>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

import Chat from "@/views/event-room/Chat";
import EmployeesManagementList from "@/views/event-room/EmployeesManagementList.vue";
import EventInfos from "@/views/event-room/EventInfos";
import FileUploader from "@/views/event-room/FileUploader";
import VideoCall from "@/views/event-room/VideoCall";
import WhiteBoard from "@/views/event-room/WhiteBoard";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import {
  BRow, BCol
} from "bootstrap-vue";
import EventHeader from "@/views/event-room/EventHeader";


export default {
  components: {
    EventHeader,
    BRow,
    BCol,
    Chat,
    EmployeesManagementList,
    EventInfos,
    FileUploader,
    WhiteBoard,
    LightBox,
    VideoCall
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showLightBox: false,
      currentItem: null,
      loaded: true,
      layout: "",
      layouts: "",
      draggable: true,
      resizable: false,
      responsive: true,
      breakPoint: '',
      setLayout: false,
      booking_id: this.$route.params.booking_id
    };
  },
  mounted() {
    this.getCurrentEvent();
    this.setLayout = false
  },

  beforeCreate(){
    const spinnerTxt = this.$i18n.locale === 'en' ? 'Loading Event Room' : 'Event Raum wird geladen'

    const appLoadingText = document.getElementById('spinner_txt')
    appLoadingText.innerText = spinnerTxt


  },
  methods: {
    getCurrentEvent() {
      let itemUrl = `/client/booking/${this.$route.params.booking_id}`;
      this.$http
          .get(itemUrl)
          .then((response) => {
            this.currentItem = response.data;
            // this.setPositionOfCard();
          })
          .catch((error) => {
          });
    },

    // file preview
    viewFileLightBox(e) {
      let fileType = e.type;
      let url = `events/view-file/${e.id}`;
      let accessToken = JSON.parse(
          localStorage.getItem("userData")
      ).access_token;
      let baseUrl =
          process.env.VUE_APP_ROOT_API + url + "?token=" + accessToken;
      if (["image/jpeg", "image/png", "image/jpg"].includes(fileType)) {
        this.media = [
          {
            type: "image",
            thumb: baseUrl,
            src: baseUrl,
          },
        ];
        this.showLightBox = true;
      } else {
        window.open(baseUrl, "_blank");
      }
    },

    //close lightbox
    closeLightBox() {
      this.showLightBox = false;
    },
  },
};
</script>
<style>
</style>
