<template>
  <div>
    <b-card title="White Board using Miro Boards">
      <b-img
          :src="require('@/assets/images/placeholders/WhitBoard.png')"
          height="350"
          width="750"
      />
    </b-card>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BFormRating,
  BRow,
    BEmbed,
    BImg,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  props: {
    expert: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormRating,
    BRow,
    BCol,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BEmbed,
    BImg,
  },

}
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.video-nuggets-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 220px;
}
.video-nuggets {
  max-height: 200px;
  min-height: 200px;
}

.slogan-videonuggets {
  min-height: 120px;
}
</style>
