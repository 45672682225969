<template>
  <b-row>
    <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
      <b-card class="text-center h-100" :title="event.event.name">
        <!-- :title="event.event.name" -->
        <b-card-body class="pt-0">
          <b-card-img
              v-if="event.event.image"
              :src="event.event.image.url"
              class="event-details"
          ></b-card-img>
          <b-card-text class="mt-3 event-content-color">{{
              event.informations
            }}
          </b-card-text>
          <b-card-title class="mb-1" v-if="event.event.eventlocation == 1">
            <b-card-text>{{ event.event.street }}</b-card-text>
            <b-card-text class="event-content-color">{{
                event.event.city + "-" + event.event.postal
              }}
            </b-card-text>
          </b-card-title>
          <b-card-text v-else> {{ $t("This Event is happening online") }}</b-card-text>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
      <b-card :title="$t('This event will be hosted by')" class="text-center  h-100">
        <b-card-body class="pt-0 pb-0 mt-3">
          <b-card-img
              v-if="event.expert.expertdetails.avatar"
              :src="event.expert.expertdetails.avatar.url"
              class="expert-details"
          ></b-card-img>
          <b-card-title class="mb-1 mt-2 mb-2">{{
              event.expert.name
            }}
          </b-card-title>
          <b-card-text class="event-content-color">{{
              event.expert.expertdetails.slogan
            }}
          </b-card-text>

          <b-card-title class="mb-0 mt-2">Experttopics</b-card-title>
          <b-card-text class="expert-topics">
            <b-badge
                pill
                v-for="topic in event.expert.expertdetails.experttopics"
                :key="topic.id"

            >{{ topic.name }}
            </b-badge
            >
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
      <b-card :title="$t('Upcoming Appointments')" class="text-center  h-100">
        <b-card-body class="pt-0 mt-3">
          <b-row>
            <b-card-text class="mx-auto">
              <b-row>
                <b-col cols="1">
                  <feather-icon class="ml-1" size="2x" icon="CalendarIcon"/>
                </b-col>
                <b-col cols="0">
                  <div
                      v-for="(slot, tIndex) in event.timeslots"
                      :key="tIndex"
                  >
                    <b-badge pill class="mb-2 ml-4">

                      {{eventDate(slot.start_date,slot.end_date)}}
                    </b-badge
                    >
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BOverlay,
  BRow,
  BTable,
  BCardText,
  BCardImg,
  BCardTitle,
  VBTooltip,
  BIcon,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from 'moment'

export default {
  props: {
    expert: {
      type: Object,
      required: true,
    },
    event: {
      type: Object || Array,
      default: {} || [],
    },
  },

  components: {
    BCardTitle,
    BCardImg,
    BCardText,
    BCardGroup,
    BCard,
    BBadge,
    BAvatar,
    BFormGroup,
    BFormInput,
    BFormRating,
    BTable,
    BCardBody,
    BCardFooter,
    BRow,
    BCarousel,
    BCarouselSlide,
    BCol,
    BOverlay,
    BIcon,
    BButton,
    vSelect,
    VBTooltip,
  },

  data() {
    return {
      currentItem: null,
      loaded: false,
      slide: 0,
      sliding: null,
      interval: 3000,
      items: [],
      search: "",
      filter: {
        eventtypes: [],
      },
      filterOptions: {
        eventtypes: [],
      },
    };
  },

  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  methods: {
    formatter: (value) => {
      return moment(String(value))
          .format('DD.MM.YYYY HH:mm')
    },
    formatterH: (value) => {
      return moment(String(value))
          .format('HH:mm')
    },
    eventDate(StartDate, EndDate) {
      if (StartDate === EndDate) {
        return this.formatter(StartDate) + ' - ' + this.formatterH(EndDate)
      } else return this.formatter(StartDate) + ' - ' + this.formatter(EndDate)
    },
  }
};
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.expert-events-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  min-height: 700px;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.carousel-img > img {
  max-height: 200px !important;
  min-height: 200px !important;
  object-fit: cover !important;
  object-position: center;
  width: 100%;
  height: 100%;
}

.carousel-interval {
  no-wrap: true;
}

.slogan-expert {
  min-height: 110px;
}

.event-locationsection {
  min-height: 70px;
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.duration {
  text-align: left !important;
  margin-left: 1px;
}

.clock-icon {
  margin-top: 2px;
}

.host-info {
  text-align: center !important;
}

.credits {
  text-align: right !important;
  margin-left: 60px;
}

.minutes {
  margin-left: 5px;
}

.credits-amount {
  margin-left: 5px;
}

.event-location {
  text-align: center !important;
  justify-content: center;
}

.events-duration {
  margin-left: 10px;
}

.events-credits {
  text-align: right !important;
  margin-right: 10px;
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  visibility: hidden;
  opacity: 1;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
  visibility: visible;
  animation-duration: 1s;
  transition-timing-function: linear;
}

.carousel:hover .carousel-control-prev {
  animation-name: FadeInPrev;
}

.carousel:hover .carousel-control-next {
  animation-name: FadeInNext;
}

@keyframes FadeInPrev {
  0% {
    opacity: 0;
    padding-left: 40px;
  }
  100% {
    opacity: 1;
    padding-left: 0px;
  }
}

@keyframes FadeInNext {
  0% {
    opacity: 0;
    padding-right: 40px;
  }
  100% {
    opacity: 1;
    padding-right: 0px;
  }
}

.event-details {
  height: 150px;
  background: lightblue;
}

.expert-details {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  background: lightblue;
}

.expert-topics {
  display: flex;
  gap: 13px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 13px;
  justify-content: center;
}

.event-infos-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.event-content-color {
  color: #b9b6b7;
}
</style>
